import http from '@/utils/http'

const category = {
  add(data) {
    return http.post('/api/category/add', data)
  },
  list(data) {
    return http.post('/api/category/list', data)
  },
  update(data) {
    return http.post('/api/category/update', data)
  },
  delete(data) {
    return http.post('/api/category/delete', data)
  }
}

export default category;