import http from '@/utils/http'

const product = {
  create(data) {
    return http.post('/api/product/create', data)
  },
  list(data) {
    return http.post('/api/product/list', data)
  },
  update(data) {
    return http.post('/api/product/update', data)
  },
  delete(data) {
    return http.post('/api/product/delete', data)
  },
  skuList(data) {
    return http.post('/api/productsku/list', data)
  },
  updateStock(data) {
    return http.post('/api/productsku/updatestock', data)
  },
  updateEnable(data) {
    return http.post('/api/productsku/updateenable', data)
  },
  updateSort(data) {
    return http.post('/api/productsku/updatesort', data)
  },
  updateDiscount(data) {
    return http.post('/api/productsku/updatediscount', data)
  },
}

export default product;